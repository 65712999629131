import { render, staticRenderFns } from "./subnavs.vue?vue&type=template&id=25c6f78c&scoped=true"
import script from "./subnavs.vue?vue&type=script&lang=js"
export * from "./subnavs.vue?vue&type=script&lang=js"
import style0 from "./subnavs.vue?vue&type=style&index=0&id=25c6f78c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25c6f78c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default})
